import React, { useState, useEffect, useRef } from "react";
import "./Card.css";

import {
  TrashIcon,
  DocumentDuplicateIcon,
  ClipboardIcon,
} from "@heroicons/react/24/outline";

import CompleteInput from "./Components/CompleteInput";
import LabelSelector from "./Components/LabelSelector";
import RecurringInput from "./Components/RecurringInput";
import TimeSection from "./Components/TimeSection";
import Subtasks from "./Components/Subtasks";
import { useInViewport } from "react-in-viewport";

import {
  deleteTask,
  duplicateTask,
  softDelete,
} from "../../../redux/tasksSlice";

import { useSelector, useDispatch } from "react-redux";
import { Popover } from "antd";

import SubtasksIcon from "./Components/Subtasks/SubtaskIcon";

import moment from "moment";
import { useHotkeys } from "react-hotkeys-hook";

import _, { isEqual } from "lodash";
import {
  toggleBulkSelectForTasks,
  setCardModalActive,
} from "../../../redux/appSlice";
import NotesIcon from "./Components/NotesIcon";
import EmailIcon from "./Components/EmailIcon";
import { useMediaQuery } from "react-responsive";
import DueDateIndiciator from "./Components/DueDateIndicator";
import {
  getClickupLinkForTask,
  getCraftDocsLinkForTask,
  getFigmaLinkForTask,
  getGoogleDocsLinkForTask,
  getLinearLinkForTask,
  getNotionLinkForTask,
  getSlackLinkForTask,
  getThemeFromUser,
  isDev,
} from "../../../utils";
import { HiClipboardCopy } from "react-icons/hi";
import PriorityIndicator from "./Components/PriorityIndicator";
import PrioritySelector from "../../Generics/PrioritySelector";
import { RiAttachment2, RiNotionFill } from "react-icons/ri";
import { FaFigma } from "react-icons/fa6";
import { IoMdAttach } from "react-icons/io";
import ActiveTimerIndicator from "./Components/TimeSection/ActiveTimerIndicator";

function Card({
  item,
  index,
  activelySelected,
  manuallySelectColumn,
  navigatedViaKeyboard = false,
  columnId,
  mini = false,
  alwaysShowDueDate = false,
  disableCompletedOpacity = false,
}) {
  const bulkSelectedTasks = useSelector(
    (state) => state.app.bulkSelectedTasks || []
  );

  const priority_settings_enabled = useSelector(
    (state) =>
      state.app.currentUser.power_feature_settings?.priority_enabled || false
  );

  const attachmentsEnabled = useSelector(
    (state) =>
      state.app.currentUser.power_feature_settings?.attachments_enabled || false
  );

  const { active: createModalActive } = useSelector(
    (state) => state.app.createTaskModalActive || {}
  );

  const rich_links = useSelector(
    (state) => state.app.currentUser.rich_links ?? {},
    (prev, next) => isEqual(prev, next)
  );
  const user_theme = useSelector(
    (state) => state.app.currentUser.user_theme ?? "system"
  );

  const quickCaptureActive = useSelector(
    (state) => state.app.quickCaptureActive || false
  );

  const cardModalActiveFor = useSelector(
    (state) => state.app.cardModalActiveFor
  );

  const ref = useRef(null);
  const { inViewport, enterCount, leaveCount } = useInViewport(ref);

  const dispatch = useDispatch();
  const [labelPickerActive, setLabelPickerActive] = useState(false);
  const [prioritySelectorVisible, setPrioritySelectorVisible] = useState(false);
  const [timeSectionActive, setTimeSectionActive] = useState(false);
  const [subtasksActive, setSubtasksActive] = useState(false);
  const [descriptionEditable, setDescriptionEditable] = useState(
    item?.description || ""
  );

  // Get time format from user
  const time_format = useSelector(
    (state) => state.app.currentUser.time_format ?? "12_hour"
  );

  const timeFormat = time_format === "24_hour" ? "HH:mm" : "h:mma";

  useEffect(() => {
    if (navigatedViaKeyboard && activelySelected && !inViewport) {
      ref.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [activelySelected, inViewport, navigatedViaKeyboard, ref]);

  const [recurringTaskMenuOpen, setRecurringTaskMenuOpen] = useState(false);

  useHotkeys(
    "cmd+l, ctrl+l",
    (e) => {
      e.preventDefault();

      if (labelPickerActive) {
        setLabelPickerActive(false);
        // Let's focus on input
      } else {
        setLabelPickerActive(true);
      }

      if (timeSectionActive) {
        setTimeSectionActive(false);
      }

      if (subtasksActive) {
        setSubtasksActive(false);
      }

      // ... set up our own saving dialog.
    },
    {
      enabled:
        activelySelected &&
        (!createModalActive ? true : false) &&
        !cardModalActiveFor,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [
      activelySelected,
      createModalActive,
      labelPickerActive,
      subtasksActive,
      timeSectionActive,
      cardModalActiveFor,
    ]
  );

  useHotkeys(
    "cmd+i, ctrl+i",
    (e) => {
      e.preventDefault();

      if (timeSectionActive) {
        setTimeSectionActive(false);
        // Let's focus on input
      } else {
        setTimeSectionActive(true);
      }

      // ... set up our own saving dialog.
      if (subtasksActive) {
        setSubtasksActive(false);
      }

      if (labelPickerActive) {
        setLabelPickerActive(false);
      }

      if (prioritySelectorVisible) {
        setPrioritySelectorVisible(false);
      }
    },
    {
      enabled: (activelySelected ? true : false) && !cardModalActiveFor,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [
      activelySelected,
      timeSectionActive,
      subtasksActive,
      labelPickerActive,
      prioritySelectorVisible,
      cardModalActiveFor,
    ]
  );

  useHotkeys(
    "s",
    (e) => {
      e.preventDefault();

      if (subtasksActive) {
        setSubtasksActive(false);
      } else {
        setSubtasksActive(true);
      }

      if (timeSectionActive) {
        setTimeSectionActive(false);
      }

      if (labelPickerActive) {
        setLabelPickerActive(false);
      }

      if (prioritySelectorVisible) {
        setPrioritySelectorVisible(false);
      }

      // ... set up our own saving dialog.
    },
    {
      enabled: (activelySelected ? true : false) && !cardModalActiveFor,
    },
    [
      activelySelected,
      subtasksActive,
      timeSectionActive,
      labelPickerActive,
      cardModalActiveFor,
    ]
  );

  useHotkeys(
    "enter, cmd+enter, ctrl+enter",
    (e) => {
      if (cardModalActiveFor !== item.id) {
        e.preventDefault();
        dispatch(setCardModalActive(item.id));
      }

      // ... set up our own saving dialog.
    },
    {
      enabled:
        !quickCaptureActive &&
        activelySelected &&
        !labelPickerActive &&
        !timeSectionActive &&
        !subtasksActive &&
        !recurringTaskMenuOpen &&
        !cardModalActiveFor,
      enableOnTags: [],
    },
    [
      activelySelected,
      labelPickerActive,
      subtasksActive,
      timeSectionActive,
      cardModalActiveFor,
      recurringTaskMenuOpen,
    ]
  );

  useHotkeys(
    "esc",
    (e) => {
      if (!labelPickerActive) {
        e.preventDefault();
        manuallySelectColumn(columnId, -1);
      }
      // ... set up our own saving dialog.
    },
    {
      enabled:
        !quickCaptureActive &&
        activelySelected &&
        cardModalActiveFor !== item.id
          ? true
          : false,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [activelySelected, labelPickerActive, cardModalActiveFor]
  );

  useHotkeys(
    "cmd+backspace, ctrl+backspace, ctrl+delete",
    (e) => {
      e.preventDefault();
      dispatch(softDelete(item));

      // ... set up our own saving dialog.
    },
    {
      enabled: activelySelected ? true : false,
    },
    [activelySelected]
  );

  useEffect(() => {
    if (item.description && item.description !== descriptionEditable) {
      setDescriptionEditable(item.description);
    }
  }, [item?.description]);

  useEffect(() => {
    // Close everything is item is marked as complete
    if (item.complete) {
      setLabelPickerActive(false);
      setPrioritySelectorVisible(false);
      setTimeSectionActive(false);
      setSubtasksActive(false);
    }
  }, [item?.complete]);

  function generateItemStartTime(item) {
    var startTime = null;
    var itemDate = null;

    if (item == null) return null;

    if (item?.start?.toDate) {
      startTime = item?.start?.toDate();
    }

    if (item?.date?.toDate) {
      itemDate = item?.date?.toDate();
    }

    if (item?.start && moment(item?.start).isValid()) {
      startTime = item?.start;
    }

    if (item?.date && moment(item?.date).isValid()) {
      itemDate = item?.date;
    }

    if (!startTime || !itemDate) {
      return null;
    }

    // If startTime and date are the day, it is valid
    if (moment(startTime).isSame(itemDate, "day")) {
      return moment(startTime).format(timeFormat);
    }

    return null;
  }

  const isTargetClassValid = (className) => {
    if (typeof className === "string") {
      const targetClasses = [
        "card-container",
        "card-header",
        "card-content",
        "card-footer",
        "card-left-buttons",
        "card-description",
        "card-description-mini",
        "timer-indicator",
      ];

      return targetClasses.some((targetClass) =>
        className.includes(targetClass)
      );
    } else if (className instanceof DOMTokenList) {
      const targetClasses = [
        "card-container",
        "card-header",
        "card-content",
        "card-footer",
        "card-left-buttons",
        "card-description",
        "card-description-mini",
        "timer-indicator",
      ];

      return targetClasses.some((targetClass) =>
        className.contains(targetClass)
      );
    }

    return false;
  };

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  const theme = getThemeFromUser(user_theme);

  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if context menu is open and click is outside the menu and card
      if (isContextMenuOpen) {
        const contextMenu = document.getElementById("card-context-menu-card");

        // Don't close if clicking on an action button within the menu
        if (event.target.closest(".context-menu-row")) {
          return;
        }

        if (contextMenu && !contextMenu.contains(event.target)) {
          console.log("closing context menu");
          setIsContextMenuOpen(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isContextMenuOpen]);

  return (
    <Popover
      placement="right"
      title={null}
      content={
        <div className="card-context-menu">
          <div
            onClick={() => {
              dispatch(
                duplicateTask({ taskId: item.id, taskToDuplicate: item })
              );
              setIsContextMenuOpen(false);
            }}
            className="context-menu-row"
          >
            <DocumentDuplicateIcon className="context-mmenu-row-icon" />
            Duplicate
          </div>
          <div
            onClick={(e) => {
              // Prevent default and stop propagation

              // Execute the delete action first
              dispatch(softDelete(item));

              // Use setTimeout to ensure the action completes before closing menu
              setTimeout(() => {
                setIsContextMenuOpen(false);
              }, 50);
            }}
            className="context-menu-row"
          >
            <TrashIcon className="context-mmenu-row-icon" />
            Delete
          </div>
          {isDev && (
            <div
              onClick={() => {
                navigator.clipboard.writeText(item.id);
                setIsContextMenuOpen(false);
              }}
              className="context-menu-row"
            >
              <ClipboardIcon className="context-mmenu-row-icon" />
              Copy ID
            </div>
          )}
        </div>
      }
      open={isContextMenuOpen}
      onOpenChange={(open) => {
        setIsContextMenuOpen(open);
      }}
      trigger={[]}
      id="card-context-menu-card"
    >
      <div
        taskId={item.id}
        onContextMenu={(e) => {
          e.preventDefault();
          setIsContextMenuOpen(true);
        }}
        className={
          mini
            ? `card-draggable card-description-mini dark:text-neutral-200 select-none pb-2 pt-1 px-2 border rounded-lg hover:bg-cardBackgroundLight dark:hover:bg-cardBackgroundDark hover:border hover:border-neutral-300 dark:hover:border-cardBorderColorDark
             ${
               activelySelected
                 ? "bg-cardBackgroundLight dark:bg-cardBackgroundDark border border-solid !border-neutral-300 dark:border-cardBorderColorDark shadow-sm"
                 : ""
             } ${
                bulkSelectedTasks.includes(item.id)
                  ? " border border-solid !border-purple-500 hover:border-purple-500 dark:hover:border-purple-500"
                  : "border-transparent"
              }`
            : `card-draggable card-container ${
                item.complete && !disableCompletedOpacity ? " complete" : ""
              } ${activelySelected ? " activelySelected" : ""} ${
                bulkSelectedTasks.includes(item.id)
                  ? " border border-solid !border-purple-500"
                  : ""
              }
              `
        }
        onMouseEnter={() => {
          if (activelySelected) {
            return;
          }
          if (manuallySelectColumn && !navigatedViaKeyboard) {
            manuallySelectColumn(columnId, index);
          }
        }}
        onMouseLeave={() => {
          if (
            activelySelected &&
            manuallySelectColumn &&
            !navigatedViaKeyboard
          ) {
            manuallySelectColumn(columnId, -1);
          }
        }}
        onClick={(e) => {
          // If shift+click, we want to dispatch toggleBulkSelectForTasks
          // Else, we want to dispatch setCardModalActive

          if (isTargetClassValid(e.target.className)) {
            if (e.shiftKey) {
              dispatch(toggleBulkSelectForTasks([item.id]));
            } else {
              dispatch(setCardModalActive(item.id));
            }
          }
        }}
        id={item.id}
        ref={ref}
      >
        <div
          className={
            mini
              ? "card-description-mini flex flex-row items-center gap-2"
              : "card-header"
          }
        >
          <CompleteInput
            item={item}
            complete={item?.complete}
            activelySelected={activelySelected && !createModalActive}
            noCheckbox={true}
          />
          <div
            spellCheck="false"
            className={
              mini
                ? "card-description-mini cursor-pointer mt-[2px] bg-transparent !text-sm overflow-hidden overflow-ellipsis whitespace-nowrap ph-mask"
                : "card-description no-hover ph-mask"
            }
            data-no-dnd="true"
          >
            {descriptionEditable}
          </div>

          {!mini && (
            <div
              onClick={(event) => {
                setTimeSectionActive(!timeSectionActive);
              }}
              className="card-time-estimate"
              data-no-dnd="true"
            >
              {!isNaN(item.estimated_time)
                ? moment.utc(item.estimated_time * 1000).format("H:mm")
                : "0:00"}
            </div>
          )}
        </div>
        {!mini && (
          <>
            <div className="card-footer">
              <div className="card-left-buttons">
                <LabelSelector
                  label={item?.label}
                  key={item.id + "_label_selector"}
                  item={item}
                  labelPickerActive={labelPickerActive}
                  setLabelPickerActive={setLabelPickerActive}
                />

                {!rich_links["slack"]?.disabled &&
                  getSlackLinkForTask(item) && (
                    <div className="hover:">
                      <img
                        src={require("../../../images/Slack-mark-RGB.png")}
                        alt="Slack Logo"
                        className="w-3 h-3 object-contain"
                        onClick={(e) => {
                          // window.open(
                          //   getSlackLinkForTask(
                          //     item,
                          //     rich_links["slack"]?.deeplink_disabled
                          //   )
                          // );

                          dispatch(setCardModalActive(item.id));
                        }}
                      />
                    </div>
                  )}

                {!rich_links["notion"]?.disabled &&
                  getNotionLinkForTask(item) && (
                    <div className="">
                      {theme === "light" ? (
                        <RiNotionFill
                          className={`w-4 h-4 object-contain `}
                          onClick={(e) => {
                            // const link = getNotionLinkForTask(
                            //   item,
                            //   rich_links["notion"]?.deeplink_disabled
                            // );
                            // window.open(link);

                            dispatch(setCardModalActive(item.id));
                          }}
                        />
                      ) : (
                        <img
                          src={require("../../../images/notion-logo-no-background.png")}
                          className="w-3.5 h-3.5 object-contain"
                          alt="notion logo"
                        />
                      )}
                    </div>
                  )}

                {!rich_links["figma"]?.disabled &&
                  getFigmaLinkForTask(item) && (
                    <div className="">
                      <img
                        src={require("../../../images/figma-logo.png")}
                        alt="Figma Logo"
                        className="w-3 h-3 object-contain"
                        onClick={(e) => {
                          // const link = getFigmaLinkForTask(
                          //   item,
                          //   rich_links["figma"]?.deeplink_disabled
                          // );
                          // window.open(link);

                          dispatch(setCardModalActive(item.id));
                        }}
                      />
                    </div>
                  )}

                {!rich_links["craft"]?.disabled &&
                  getCraftDocsLinkForTask(item) && (
                    <div className="">
                      <img
                        src={require("../../../images/craft-logo.png")}
                        alt="Craft Logo"
                        className="w-3 h-3 object-contain"
                        onClick={(e) => {
                          // const link = getFigmaLinkForTask(
                          //   item,
                          //   rich_links["figma"]?.deeplink_disabled
                          // );
                          // window.open(link);

                          dispatch(setCardModalActive(item.id));
                        }}
                      />
                    </div>
                  )}

                {!rich_links["linear"]?.disabled &&
                  getLinearLinkForTask(item) && (
                    <div className="">
                      <img
                        src={require("../../../images/linear-logo.png")}
                        alt="Linear Logo"
                        className="w-3 h-3 object-contain"
                        onClick={(e) => {
                          // const link = getFigmaLinkForTask(
                          //   item,
                          //   rich_links["figma"]?.deeplink_disabled
                          // );
                          // window.open(link);

                          dispatch(setCardModalActive(item.id));
                        }}
                      />
                    </div>
                  )}

                {!rich_links["googleDocs"]?.disabled &&
                  getGoogleDocsLinkForTask(item) && (
                    <div className="">
                      <img
                        src={require("../../../images/google-docs-logo.png")}
                        className="w-3 h-3 object-cover"
                        alt="Google Docs Logo"
                        onClick={(e) => {
                          dispatch(setCardModalActive(item.id));
                        }}
                      />
                    </div>
                  )}

                {!rich_links["clickUp"]?.disabled &&
                  getClickupLinkForTask(item) && (
                    <div className="">
                      <img
                        src={require("../../../images/clickup-logo.png")}
                        className="w-3 h-3 object-contain"
                        alt="ClickUp Logo"
                        onClick={(e) => {
                          dispatch(setCardModalActive(item.id));
                        }}
                      />
                    </div>
                  )}

                {priority_settings_enabled && (
                  <PrioritySelector
                    priority={item?.priority}
                    modal={false}
                    item={item}
                    prioritySelectorVisible={prioritySelectorVisible}
                    setPrioritySelectorVisible={setPrioritySelectorVisible}
                  />
                )}
                {generateItemStartTime(item) && (
                  <div className="task-time">{generateItemStartTime(item)}</div>
                )}
                <RecurringInput
                  item={item}
                  recurringTaskMenuOpen={recurringTaskMenuOpen}
                  setRecurringTaskMenuOpen={setRecurringTaskMenuOpen}
                />

                {attachmentsEnabled && item?.attachments?.length > 0 && (
                  <div className="flex flex-row gap-0.5 items-center text-neutral-500 font-medium">
                    <RiAttachment2 className="w-3 h-3 object-contain" />
                    <span className="text-[10px]">
                      {item?.attachments?.length}
                    </span>
                  </div>
                )}

                <SubtasksIcon
                  action={() => {
                    setSubtasksActive(!subtasksActive);
                  }}
                  item={item}
                />

                <NotesIcon
                  action={() => {
                    dispatch(setCardModalActive(item.id));
                  }}
                  item={item}
                />
                <EmailIcon
                  action={() => {
                    dispatch(setCardModalActive(item.id));
                  }}
                  item={item}
                />
                <DueDateIndiciator
                  action={() => {
                    dispatch(setCardModalActive(item.id));
                  }}
                  item={item}
                  alwaysVisible={alwaysShowDueDate}
                />
              </div>
            </div>
            <TimeSection
              item={item}
              activelySelected={activelySelected && !createModalActive}
              timeSectionActive={timeSectionActive}
              setTimeSectionActive={setTimeSectionActive}
            />

            <Subtasks
              item={item}
              activelySelected={activelySelected && !createModalActive}
              subtasksActive={subtasksActive}
              setSubtasksActive={setSubtasksActive}
              modalMode={false}
            />
          </>
        )}
        {!mini && <ActiveTimerIndicator item={item} />}
      </div>
    </Popover>
  );
}
// Custom areEqual function to check if we should re-render
function areEqual(prev, next) {
  // Check the item prop using lodash _.isEqual
  return (
    _.isEqual(prev.item, next.item) &&
    prev.activelySelected === next.activelySelected &&
    prev.navigatedViaKeyboard === next.navigatedViaKeyboard &&
    prev.index === next.index &&
    prev.mini === next.mini &&
    prev.alwaysShowDueDate === next.alwaysShowDueDate &&
    prev.disableCompletedOpacity === next.disableCompletedOpacity
  );
}

// Use React.memo to prevent unnecessary re-renders
export default React.memo(Card, areEqual);
