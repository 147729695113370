import React, { useEffect, useState } from "react";
import { Dropdown } from "antd";
import { IoChevronDown } from "react-icons/io5";

const CustomDropdown = ({
  options,
  onSelect,
  value,
  defaultValue,
  className = "",
  leadingIcon,
  disabled,
  isSelectDatabase = false,
}) => {
  const [selected, setSelected] = useState(
    defaultValue || options[0]?.valueLabel || options[0]?.label || "Select"
  );

  const handleSelect = ({ key }) => {
    const selectedOption = options.find((option) => option.key === key);
    setSelected(selectedOption.valueLabel || selectedOption.label);
    onSelect(selectedOption);
  };

  const menuItems = options.map((option) => ({
    key: option.key,
    label: option.label,
  }));

  useEffect(() => {
    if (value && options.length > 0) {
      const selectedOption = options.find((option) => option.key === value);
      if (selectedOption) {
        setSelected(selectedOption.valueLabel || selectedOption.label);
      } else {
        setSelected(defaultValue || "Select");
      }
    } else {
      setSelected(defaultValue || "Select");
    }
  }, [value, options, defaultValue]);

  const dropdownRender = (menu) => (
    <div className="border border-neutral-200 dark:border-neutral-700 rounded-lg shadow-lg">
      <style>
        {`
        .ant-dropdown-menu {
          max-height: 250px;
          overflow: auto;
        }
        `}
      </style>
      {menu}
    </div>
  );

  return (
    <Dropdown
      menu={{ items: menuItems, onClick: handleSelect }}
      trigger={["click"]}
      dropdownRender={dropdownRender}
    >
      <div
        className={`p-2 px-4 border dark:border-neutral-500 rounded-lg flex justify-between items-center cursor-pointer hover:bg-neutral-100/20 hover:border-neutral-300 select-none ${className} ${
          !isSelectDatabase && disabled && "text-neutral-200"
        }`}
      >
        <div className="flex flex-row gap-2 items-center overflow-hidden">
          {leadingIcon && leadingIcon}
          <span className="truncate">{selected}</span>
        </div>
        <IoChevronDown className="flex-shrink-0" />
      </div>
    </Dropdown>
  );
};

export default CustomDropdown;