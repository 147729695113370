import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import { FiPlus } from "react-icons/fi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { notionServerUrl } from "../../../utils";
import { updateCurrentUser } from "../../../redux/appSlice";
import NotionDatabaseItem from "./NotionDatabaseItem";
import { motion, AnimatePresence } from "framer-motion";
import {
  collection,
  deleteDoc,
  deleteField,
  doc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { isDesktopApp } from "@todesktop/client-core/platform/todesktop";
import { platform } from "@todesktop/client-core";

const containerVariants = {
  hidden: { opacity: 0, x: "100%" },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "tween",
      ease: "anticipate",
      duration: 0.5,
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
  exit: {
    opacity: 0,
    x: "100%",
    transition: {
      type: "tween",
      ease: "anticipate",
      duration: 0.5,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 200,
      damping: 30,
    },
  },
};

export default function ManageNotionIntegration({
  backButtonClicked,
  selectedWorkspace,
}) {
  const [notionDatabases, setNotionDatabases] = useState([]);
  const [reAuthRequired, setReAuthRequired] = useState(false);
  const [loadingNotionFetch, setLoadingNotionFetch] = useState(false);
  const [notionConnections, setNotionConnections] = useState({});
  const [newConnection, setNewConnection] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const notion_connections = useSelector(
    (state) => state.app.notion_connections || {}
  );

  const userId = useSelector((state) => state.app.uid);

  const notion_workspaces = useSelector(
    (state) => state.app.currentUser?.notion || {}
  );

  const [notionUser, setNotionUser] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedWorkspace) {
      getNotionDatabases();

      const connections = Object.values(notion_connections).filter(
        (connection) =>
          connection.workspace_id === selectedWorkspace.workspace_id
      );

      const connectionsObj = {};
      connections.forEach((connection) => {
        connectionsObj[connection.id] = connection;
      });

      setNotionConnections(connectionsObj);

      if (selectedWorkspace?.owner?.user) {
        setNotionUser(selectedWorkspace?.owner?.user);
      }
    }
  }, [selectedWorkspace, refresh, notion_connections]);

  const getNotionDatabases = async () => {
    try {
      setLoadingNotionFetch(true);
      const response = await axios.get(
        `${notionServerUrl}/getNotionDatabase?userId=${userId}&workspaceId=${selectedWorkspace.workspace_id}`
      );

      var databases = response.data.data.map((database) => {
        return {
          label: database.title,
          key: database.id,
          properties: database.properties,
        };
      });

      // Sort the databases by label
      databases.sort((a, b) => {
        return a.label.localeCompare(b.label);
      });

      setNotionDatabases(databases);
      setLoadingNotionFetch(false);
    } catch (error) {
      console.error("Error getting notion databases", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.code === "NOTION_REAUTH_REQUIRED"
      ) {
        setReAuthRequired(true);
      } else {
        console.error("Error getting notion databases", error);
      }
      setLoadingNotionFetch(false);
    }
  };

  useEffect(() => {
    getNotionDatabases();
  }, [refresh]);

  const workspaceRevokeAccess = async () => {
    try {
      if (!selectedWorkspace?.workspace_id) return;

      // Using firebase delete the field value
      await updateDoc(doc(db, "users", userId), {
        [`notion.${selectedWorkspace.workspace_id}`]: deleteField(),
      });

      // we also need to remove all the connections associated with this workspace
      const connections = Object.values(notion_connections).filter(
        (connection) =>
          connection.workspace_id === selectedWorkspace.workspace_id
      );

      const connectionIds = connections.map((connection) => connection.id);

      const batch = writeBatch(db);

      // let's delete all the connections from firebase
      const connectionsRef = collection(
        db,
        "users",
        userId,
        "notion_connections"
      );

      connectionIds.forEach((connectionId) => {
        const docRef = doc(connectionsRef, connectionId);
        batch.delete(docRef);
      });

      // Commit the batch
      await batch.commit();

      console.log(
        `Deleted ${connectionIds.length} connections for workspace ${selectedWorkspace.workspace_id}`
      );

      backButtonClicked();
    } catch (error) {
      console.error("Error deleting workspace", error);
    }
  };

  // Function to take in a notion workspace_icon and return a icon
  // It can either be a url, a text emoji or null
  function getWorkspaceIcon(workspace) {
    if (workspace.workspace_icon) {
      // If its a url
      if (workspace.workspace_icon.startsWith("http")) {
        return (
          <img
            className="h-10 w-10 rounded-md object-cover"
            src={workspace.workspace_icon}
            alt={`${workspace.workspace_name} logo`}
          />
        );
      } else {
        // If its a text emoji
        return (
          <div className="text-2xl h-10 w-10 rounded-md bg-neutral-100 dark:bg-neutral-800 flex items-center justify-center">
            {workspace.workspace_icon}
          </div>
        );
      }
    } else {
      return (
        <img
          className="h-10 w-10 rounded-md object-cover"
          src={require("../../../images/Notion_app_logo.png")}
          alt={`${workspace.workspace_name} logo`}
        />
      );
    }
  }

  return (
    <div className="flex flex-col gap-2 justify-start items-start w-full">
      <div
        onClick={() => {
          backButtonClicked();
        }}
        className="back-button"
      >
        <ArrowLeftCircleIcon className="back-button-icon" />
        Back to Workspaces
      </div>

      <div className="flex flex-col w-full gap-4">
        <div className="p-4 flex flex-row gap-2 justify-between border border-neutral-200 dark:border-neutral-700 rounded-lg w-full">
          <div className="flex items-center gap-2">
            {getWorkspaceIcon(selectedWorkspace)}
            <div className="flex flex-col">
              <div className="text-base font-medium">
                {selectedWorkspace?.workspace_name}
              </div>
              <div className="text-xs text-neutral-500">
                {selectedWorkspace?.owner?.user?.person?.email}
              </div>
            </div>
          </div>
          {reAuthRequired ? (
            <div
              disable={loadingNotionFetch}
              className={`${
                loadingNotionFetch ? "text-blue-200" : "text-blue-500"
              }  flex items-center rounded-md px-2 py-1 font-semibold text-xs hover:text-blue-800 cursor-pointer`}
              onClick={() => {
                if (isDesktopApp()) {
                  platform.os.openURL(
                    `https://api.notion.com/v1/oauth/authorize?client_id=870ea79c-0e00-4452-a0be-b753ec74e9f9&response_type=code&owner=user&redirect_uri=https%3A%2F%2Fapp.ellieplanner.com%2Fnotion-oauth`
                  );
                } else {
                  window.open(
                    `https://api.notion.com/v1/oauth/authorize?client_id=870ea79c-0e00-4452-a0be-b753ec74e9f9&response_type=code&owner=user&redirect_uri=https%3A%2F%2Fapp.ellieplanner.com%2Fnotion-oauth`
                  );
                }
              }}
            >
              Re-authenticate
            </div>
          ) : (
            <div
              disable={loadingNotionFetch}
              className={`${
                loadingNotionFetch ? "text-red-200" : "text-red-600"
              }  flex items-center rounded-md px-2 py-1 font-semibold text-xs hover:text-red-700 cursor-pointer`}
              onClick={async () => {
                await workspaceRevokeAccess();
              }}
            >
              Revoke access
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col w-full gap-4 mt-2 ">
        {Object.values(notionConnections) > 0 && (
          <div class="text-base text-neutral-600 font-medium">
            Connected databases
          </div>
        )}

        <div className="flex flex-col gap-4">
          {Object.values(notionConnections).map((connection) => {
            return (
              <NotionDatabaseItem
                key={connection.id}
                connection={connection}
                databaseName={connection.notion_database.label}
                activateEditMode={false}
                notionDatabases={notionDatabases}
                setNotionDatabases={setNotionDatabases}
                setRefresh={setRefresh}
                loadingNotionFetch={loadingNotionFetch}
                workspace={selectedWorkspace}
                notionUser={notionUser}
                isCreation={false}
              />
            );
          })}

          {newConnection && (
            <NotionDatabaseItem
              activateEditMode={true}
              notionDatabases={notionDatabases}
              setNotionDatabases={setNotionDatabases}
              setRefresh={setRefresh}
              loadingNotionFetch={loadingNotionFetch}
              workspace={selectedWorkspace}
              onClose={() => setNewConnection(false)}
              notionUser={notionUser}
              isCreation={true}
            />
          )}
          <div
            className="mt-1 flex flex-row gap-1 text-white text-base items-center justify-center px-3 py-2.5 bg-blue-600 rounded-full cursor-pointer hover:bg-blue-800"
            onClick={() => setNewConnection(true)}
          >
            <FiPlus className="text-sm font-medium" />
            <span className="text-xs font-medium">Add Connection</span>
          </div>
        </div>
      </div>
    </div>
  );
}
